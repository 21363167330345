.App {
  text-align: left;
  background-color : white;
  color : #092F6A;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
}

.CentreApplication {
  display: flex;
  flex-direction: row;
  justify-content: left;
  background-color: white;
}

.MenuModules {
  display: flex;
  flex-direction: column;
  background-color: rgb(170, 170, 199);
  margin: 0;
  height: 91vh;
  min-width: 5vw;
  max-width: 20vw;
}