.container {
    max-height: 90vh;
}

.outlined {
    display : flex;
    border-style: outset;
    border-width: 1px;
    border-radius: 4px;
}

.selectionEntreprise:hover {
    cursor: pointer;
}

.contenuToolbar {
    display: flex;
    justify-content: flex-end;
}

.carte {
    max-width: 20em;
}